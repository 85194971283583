exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategorije-js": () => import("./../../../src/pages/kategorije.js" /* webpackChunkName: "component---src-pages-kategorije-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nama-js": () => import("./../../../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-proizvod-js": () => import("./../../../src/pages/proizvod.js" /* webpackChunkName: "component---src-pages-proizvod-js" */),
  "component---src-pages-proizvodi-js": () => import("./../../../src/pages/proizvodi.js" /* webpackChunkName: "component---src-pages-proizvodi-js" */)
}

